/* eslint-disable react/jsx-no-target-blank */
import React, {FC} from 'react';

import {CompetitionName, CompetitionTablesTeam} from 'utils/types';
import { BECompetition } from 'API/types';

import './styles.scss';

const TableRow = ({
  team: {teamName, wins, losses, ties, all, points, trend, status, sportSeason, pointsdiff},
  rank,
  narrow
  
}: {
  team: CompetitionTablesTeam;
  rank: number;
  narrow?: boolean;
}) => (
  <div className="WidgetTables__row">
    <span className="WidgetTables__rank">{rank}</span>
    <div
      className={`WidgetTables__trend WidgetTables__trend--${trend} WidgetTables__trend--${status}`}
    />
    <span className="WidgetTables__name">{teamName}</span>
    {!narrow && <span className="WidgetTables__data WidgetTables__data--all">{all}</span>}
    <span className="WidgetTables__data WidgetTables__data--wins">{wins}</span>
      {sportSeason === "soccer" ? <span className="WidgetTables__data WidgetTables__data--ties">{ties}</span> : ""}
    <span className="WidgetTables__data WidgetTables__data--losses">{losses}</span>
      {sportSeason === "soccer" ? <span className="WidgetTables__data WidgetTables__data--points">{points}</span> : ""}
      {sportSeason === "basketball" ? <span className="WidgetTables__data WidgetTables__data--pointsdifference">{pointsdiff > 0 ? "+" : ""}{pointsdiff}</span> : ""}
  </div>
);

interface Props {
  label?: string;
  competitionTables: CompetitionTablesTeam[];
  narrow?: boolean;
}

const WidgetTables: FC<Props> = ({label, competitionTables, narrow = false}: Props) => (
  <section className="WidgetTables">
    {label && <h3 className="WidgetTables__header">{label}</h3> }
    <div className="WidgetTables__tableHeader">
      {!narrow && <span className="WidgetTables__data WidgetTables__data--all">Α</span>}
      <span className="WidgetTables__data WidgetTables__data--wins">Ν</span>
        {competitionTables[0].sportSeason === "soccer" ? <span className="WidgetTables__data WidgetTables__data--ties">Ι</span> : "" }
      <span className="WidgetTables__data WidgetTables__data--losses">Η</span>
        {competitionTables[0].sportSeason === "soccer" ? <span className="WidgetTables__data WidgetTables__data--points">ΒΑΘ</span> : ""}
        {competitionTables[0].sportSeason === "basketball" ? <span className="WidgetTables__data WidgetTables__data--pointsdifference">ΔΠ</span> : ""}
    </div>
    {competitionTables.map((row, i) => (
      <TableRow team={row} rank={i + 1} key={row.teamName} narrow={narrow} />
    ))}
  </section>
);

export default WidgetTables;
