import {ScoreboardPanelsAction, ScoreboardPanelsState} from './types';
import {
  SB_LIVE_DATA_BY_IDS_GET_INVALIDATE,
  SB_LIVE_DATA_BY_IDS_GET_REQUEST,
  SB_LIVE_DATA_BY_IDS_GET_RESPONSE,
} from '../actionTypes';

const initialState: ScoreboardPanelsState = {
  isFetching: false,
  didInvalidate: false,
};

/**
 * Derives the 'standingsWidgets' state by reducing a new one every time an involved action is dispatches.
 * If the action is not involved (meaning it's not related with this state), then the state
 * is not changed.
 * @param state
 * @param action
 */
const ScoreboardPanelsReducer = (state: ScoreboardPanelsState = initialState, action: ScoreboardPanelsAction): ScoreboardPanelsState => {
  switch (action.type) {
    case SB_LIVE_DATA_BY_IDS_GET_REQUEST:
      return ({
        ...state,
        isFetching: true,
        error: undefined,
      });
    case SB_LIVE_DATA_BY_IDS_GET_RESPONSE: {
      return ({
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: {
          ...(state.data || {}),
          ...Object.fromEntries(action.payload.widgets.map(w => [w.id, w.match]))
        },
      });
    }
    case SB_LIVE_DATA_BY_IDS_GET_INVALIDATE:
      return ({
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      });
    default:
      return state;
  }
};

export default ScoreboardPanelsReducer;
