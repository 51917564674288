import Loader from 'components/Loader';
import NoData from 'components/NoData';
import React, {FC, useMemo} from 'react';

import {TVProgramEvent} from 'utils/types';

import './styles.scss';

// BIQ: better solution while keeping <img> semantics? spritemap only?

import action24 from 'assets/img/tv/action24.png';
import alpha from 'assets/img/tv/alpha.png';
import ant1 from 'assets/img/tv/ant1.png';
import cosmote_sport_high from 'assets/img/tv/cosmote_sport_high.png';
import epsilontv from 'assets/img/tv/epsilontv.png';
import ert_1 from 'assets/img/tv/ert_1.png';
import ert_2 from 'assets/img/tv/ert_2.png';
import ert_3 from 'assets/img/tv/ert_3.png';
import ert_hd from 'assets/img/tv/ert_hd.png';
import ert_play1 from 'assets/img/tv/ert_play1.png';
import ert_play2 from 'assets/img/tv/ert_play2.png';
import ert_play3 from 'assets/img/tv/ert_play3.png';
import ert_play4 from 'assets/img/tv/ert_play4.png';
import espn_america from 'assets/img/tv/espn_america.png';
import eurosport_1 from 'assets/img/tv/eurosport_1.png';
import eurosport_2 from 'assets/img/tv/eurosport_2.png';
import extra3 from 'assets/img/tv/extra3.png';
import fox_sports_hd from 'assets/img/tv/fox_sports_hd.png';
import fox from 'assets/img/tv/fox.png';
import kriti_tv from 'assets/img/tv/kriti_tv.png';
import makedonia_tv from 'assets/img/tv/makedonia_tv.png';
import mega from 'assets/img/tv/mega.png';
import novasport_1 from 'assets/img/tv/novasport_1.png';
import novasport_2 from 'assets/img/tv/novasport_2.png';
import novasport_3 from 'assets/img/tv/novasport_3.png';
import novasport_4 from 'assets/img/tv/novasport_4.png';
import novasport_5 from 'assets/img/tv/novasport_5.png';
import novasport_6 from 'assets/img/tv/novasport_6.png';
import novasports_start from 'assets/img/tv/novasports_start.png';
import novasports_prime from 'assets/img/tv/novasports_prime.png';
import novasports_news from 'assets/img/tv/novasports_news.png';
import open from 'assets/img/tv/open.png';
import ote_sport_1 from 'assets/img/tv/ote_sport_1.png';
import ote_sport_2 from 'assets/img/tv/ote_sport_2.png';
import ote_sport_3 from 'assets/img/tv/ote_sport_3.png';
import ote_sport_4 from 'assets/img/tv/ote_sport_4.png';
import ote_sport_5 from 'assets/img/tv/ote_sport_5.png';
import ote_sport_6 from 'assets/img/tv/ote_sport_6.png';
import ote_sport_7 from 'assets/img/tv/ote_sport_7.png';
import ote_sport_8 from 'assets/img/tv/ote_sport_8.png';
import skai from 'assets/img/tv/skai.png';
import sport24_webtv from 'assets/img/tv/sport24_webtv.png';

const channels: {[k: string]: string} = {
  1: novasport_1,
  2: novasport_2,
  3: novasport_3,
  4: novasport_4,
  5: novasport_5,
  6: novasport_6,
  8: ote_sport_1,
  9: ote_sport_2,
  10: ote_sport_3,
  11: ert_3,
  12: ert_1,
  13: ert_hd,
  14: ert_2,
  15: action24,
  16: mega,
  17: ant1,
  18: ote_sport_4,
  19: ote_sport_5,
  20: eurosport_1,
  21: eurosport_2,
  22: sport24_webtv,
  23: ote_sport_6,
  24: skai,
  26: alpha,
  27: ote_sport_7,
  28: ote_sport_8,
  30: fox_sports_hd,
  31: kriti_tv,
  33: epsilontv,
  34: espn_america,
  35: sport24_webtv,
  37: action24,
  39: skai,
  43: fox,
  50: extra3,
  51: makedonia_tv,
  58: ert_3,
  62: open,
  88:novasports_start,
  89:novasports_prime,
  90:novasports_news,
};

interface Props {
  loading?: boolean;
  tvData?: TVProgramEvent[];
  showHeader?: boolean;
}

const WidgetTVProgram: FC<Props> = ({loading, tvData, showHeader = true}: Props) => {
  const today = useMemo(
    () => new Date().toLocaleDateString('el-GR', {weekday: 'long', day: 'numeric', month: 'long'}),
    []
  );

  return (
    <section className="WidgetTVProgram">
      { showHeader && <h3 className="WidgetTVProgram__header">ΠΡΟΓΡΑΜΜΑ ΤΗΛΕΟΡΑΣΗΣ</h3>}
      <h4 className="WidgetTVProgram__today">{today}</h4>
      {(!tvData || !tvData.length) && loading && <Loader />}
      {!tvData || !tvData.length ? (
        <NoData />
      ) : (
        tvData.map(({id, time, competition, name, channelId}) => (
          <div className="WidgetTVProgram__show" key={id}>
            <span className="WidgetTVProgram__time">{time}</span>
            <div className="WidgetTVProgram__info">
              <span className="WidgetTVProgram__competition">{competition}</span>
              <span className="WidgetTVProgram__name">{name}</span>
            </div>
            {channelId in channels && (
              <div className={`WidgetTVProgram__channel WidgetTVProgram__channel--${channelId}`}>
                <img src={channels[channelId]} alt="channel" />
              </div>
            )}
          </div>
        ))
      )}
    </section>
  );
};

export default React.memo(WidgetTVProgram);
