import React, {FC, PropsWithChildren, useMemo} from 'react';

import {EventStatus, GenericProps, MatchSimple, Sport, PeriodScore} from '../../utils/types';

import './styles.scss';

// BIQ: split this file & documentation

export interface MatchListItemProps extends GenericProps {
  match: MatchSimple;
}

interface MatchListItemWrapperProps extends GenericProps {
  id: string;
  slug: string;
}

// S24APP Usage


const MatchListItemWrapper: FC<PropsWithChildren<MatchListItemWrapperProps>> = ({
  id,
  slug,
  children,
  className,
}: PropsWithChildren<MatchListItemWrapperProps>) => {
  const isApp = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    const appParam = query.has('sport24app');
    return appParam === true;
  }, [])

  return   id ? (
    <a href={!isApp ? `/matchcenter/${slug}/${id}` : `https://www.sport24.gr/matchcenter/${slug}/${id}?sport24app=true`} className={`MatchListItem__wrapper ${className ?? ''}`}>
      {children}
    </a>
  ) : (
    <div className={`MatchListItem__wrapper ${className ?? ''}`}>{children}</div>
  );
}

const MatchListItem: FC<MatchListItemProps> = ({
  match: {
    _id,
    sport,
    sportEventSlug,
    date,
    teams,
    status,
    minute,
    matchStatus,
    stoppageTime,
    score,
    periodScores,
  },
  className,
}: MatchListItemProps) => (
  <article
    className={`MatchListItem MatchListItem--${sport} ${
      status === EventStatus.LIVE ? 'MatchListItem--live' : ''
    } ${className ?? ''}`}
  >
    <MatchListItemWrapper className="MatchListItem__wrapper" id={_id} slug={sportEventSlug}>
      <div className="MatchListItem__header">
        {status === EventStatus.NOT_STARTED ? (
          <>
            <span className="MatchListItem__date">
              {date.getDate()} {date.toLocaleString('el-GR', {month: 'short'})}
            </span>
            <span className="MatchListItem__time">{`${date.getHours()}:${date
              .getMinutes()
              .toString()
              .padStart(2, '0')}`}</span>
          </>
        ) : (
          // <>
          //   <span className="MatchListItem__status">{matchStatus}</span>
          //   {
          //     /* eslint-disable no-nested-ternary */
          //     status === EventStatus.LIVE &&
          //       minute !== null &&
          //       minute !== undefined &&
          //       matchStatus !== 'ΗΜΙ' &&
          //       matchStatus !== 'BREAK' &&
          //       (matchStatus === '2 ΗΜ' && minute === 0 ? (
          //         <span className="MatchListItem__minute">45&apos;</span>
          //       ) : ['2η ΠΕΡ', '3η ΠΕΡ', '4η ΠΕΡ'].includes(matchStatus) && minute === 0 ? (
          //         <span className="MatchListItem__minute">
          //           {(parseInt(matchStatus.slice(0, 1), 10) - 1) * 10}&apos;
          //         </span>
          //       ) : (
          //         <span className="MatchListItem__minute">
          //           {minute}
          //           {stoppageTime ? ` + ${stoppageTime}` : ''}&apos;
          //         </span>
          //       ))
          //   }
          // </>
          <>
          <span className="MatchListItem__status">{matchStatus}</span>
          {status === EventStatus.LIVE &&
            minute !== null &&
            minute !== undefined &&
            minute !== 0 &&
            matchStatus !== 'ΗΜΙ' &&
            matchStatus !== "BREAK" && (
              <span className="MatchListItem__minute">{`${minute}'${
                stoppageTime ? ` + ${stoppageTime}` : ''
              }`}</span>
            )}
        </>
        )}
      </div>
      <div className="MatchListItem__content">
        <div
          className={`MatchListItem__team MatchListItem__team--home ${
            score[0] > score[1] ? 'MatchListItem__team--winning' : ''
          }`}
        >
          <span className="MatchListItem__teamName">{teams[0].name}</span>
          <span className="MatchListItem__teamScore">
            {status === EventStatus.NOT_STARTED ? '-' : score[0]}
          </span>
        </div>

        <div
          className={`MatchListItem__team MatchListItem__team--away ${
            score[0] < score[1] ? 'MatchListItem__team--winning' : ''
          }`}
        >
          <span className="MatchListItem__teamName">{teams[1].name}</span>
          <span className="MatchListItem__teamScore">
            {status === EventStatus.NOT_STARTED ? '-' : score[1]}
          </span>
        </div>
      </div>
    </MatchListItemWrapper>
  </article>
);

export default MatchListItem;
