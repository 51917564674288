import {StandingsBySeasonGroupAction, StandingsWidgetsState} from './types';
import {
  STANDINGS_BY_SEASON_GROUP_GET_INVALIDATE,
  STANDINGS_BY_SEASON_GROUP_GET_REQUEST,
  STANDINGS_BY_SEASON_GROUP_GET_RESPONSE,
} from '../actionTypes';

const initialState: StandingsWidgetsState = {
  isFetching: false,
  didInvalidate: false,
};

/**
 * Derives the 'standingsWidgets' state by reducing a new one every time an involved action is dispatches.
 * If the action is not involved (meaning it's not related with this state), then the state
 * is not changed.
 * @param state
 * @param action
 */
const standingsWidgetsReducer = (state: StandingsWidgetsState = initialState, action: StandingsBySeasonGroupAction): StandingsWidgetsState => {
  switch (action.type) {
    case STANDINGS_BY_SEASON_GROUP_GET_REQUEST:
      return ({
        ...state,
        isFetching: true,
        error: undefined,
      });
    case STANDINGS_BY_SEASON_GROUP_GET_RESPONSE:
      return ({
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: {
          ...(state.data || {}), 
          [action.payload.widgetId]: {
            id: action.payload.widgetId,
            isCarousel: action.payload.isCarousel,
            competitionName: action.payload.competitionName,
            groups: action.payload.standingGroups
          } },
      });
    case STANDINGS_BY_SEASON_GROUP_GET_INVALIDATE:
      return ({
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      });
    default:
      return state;
  }
};

export default standingsWidgetsReducer;
