import {BetDataAction, BetDataState} from './types';
import {
  BETDATA_CLEANUP,
  BETDATA_BY_ID_GET_INVALIDATE,
  BETDATA_BY_ID_GET_REQUEST,
  BETDATA_BY_ID_GET_RESPONSE,
} from '../actionTypes';

const initialState: BetDataState = {
  isFetching: false,
  didInvalidate: false,
};

/**
 * Derives the 'detail' state by reducing a new one every time an involved action is dispatches.
 * If the action is not involved (meaning it's not related with this state), then the state
 * is not changed.
 * @param state
 * @param action
 */
const betDataReducer = (
  state: BetDataState = initialState,
  action: BetDataAction
): BetDataState => {
  switch (action.type) {
    case BETDATA_BY_ID_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case BETDATA_BY_ID_GET_RESPONSE:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: action.payload.data,
      };
    case BETDATA_BY_ID_GET_INVALIDATE:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    case BETDATA_CLEANUP:
      return {...initialState};
    default:
      return state;
  }
};

export default betDataReducer;
