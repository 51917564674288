import React, {ElementType, FC, Fragment, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import MatchListItem from 'components/MatchListItem';
import {MatchGroup} from '../../utils/types';

import './styles.scss';

interface MatchListProps {
  matchGroups: Record<string, MatchGroup>;
  showAd?: boolean;
  index?: number;
  isMobile?: boolean;
  ListItemRender?: ElementType;
}

const MatchList: FC<MatchListProps> = ({
  matchGroups,
  showAd = false,
  index,
  isMobile = false,
  ListItemRender = MatchListItem,
}: MatchListProps) => {
  const slot = useRef<any | null>(null);

  const divId = useMemo(
    () => `matchcenter-matchlist-third-party${typeof index === 'number' ? `-${index}` : ''}`,
    [index]
  );
  
  useLayoutEffect(() => {
    if (showAd && !slot.current) {
      window.googletag = window.googletag || {cmd: []};
      
      window.googletag.cmd.push(() => {
        const mapping = isMobile
          ? window.googletag
              .sizeMapping()
              .addSize([768, 0], [])
              .addSize([0, 0], [[300, 50], [300, 100], [300,250]])
              .build()
          : window.googletag
              .sizeMapping()
              .addSize([768, 0], [[300, 50], [300, 100],[300,250]])
              .addSize([0, 0], [])
              .build();
        slot.current = window.googletag
          .defineSlot(
            `/4834629/sport24.gr/HP_300x100_matchcenter`,
            [
              [300, 50],
              [300, 100],
              [300, 250],
            ],
            divId
          )
          .defineSizeMapping(mapping)
          .addService(window.googletag.pubads())
          .setTargeting('article', '')
          .setTargeting('keywords', [])
          .setTargeting('category', 'homepage')
          .setTargeting('type', 'homepage')
          .setTargeting('isAmp', 'false')
          .setTargeting('isMobile', 'false');
        
        
        if (!isMobile) {
          // display ad immediately only on desktop
          window.googletag.display(divId);
        } else {
          // if mobile, add click listener to scores button
          window.s24 = window.s24 || {};
          window.s24.matchcenterHpWidgetMobileSlots = {...(window.s24.matchcenterHpWidgetMobileSlots || {}), [divId]: slot.current};
        }
      });
    }

    return () => {
      window.googletag = window.googletag || {cmd: []};
      window.googletag.cmd.push(() => {
        if (slot.current) {
          window.googletag.destroySlots([slot.current]);
          slot.current = null;
        }
      });
      
      window.s24 = window.s24 || {};
      if (window.s24.matchcenterHpWidgetMobileSlots) delete window.s24.matchcenterHpWidgetMobileSlots[divId];
    };
  }, [divId, isMobile, showAd]);
  

  return (
    <>
      {Object.entries(matchGroups).map(([competitionId, {title: groupTitle, groupings}], i) => (
        <Fragment key={competitionId}>
          <section className="MatchList__group">
            {groupTitle && <h3 className="MatchList__title">{groupTitle}</h3>}
            {Object.entries(groupings).map(([groupingId, {title: subTitle, matches}]) => (
              <div key={groupingId}>
                {subTitle && <h4 className="MatchList__subtitle">{subTitle}</h4>}
                {matches.map(match => (
                  <ListItemRender className="MatchList__match" match={match} key={match.matchId} />
                ))}
              </div>
            ))}
          </section>
          {showAd && i === 0 && <div id={divId} className="MatchList__ad" />}
        </Fragment>
      ))}
    </>
  );
};

export default React.memo(MatchList);
