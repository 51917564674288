import {Dispatch} from 'redux';
import {getBetDataById as getAPIBetDataById} from 'API/betData';
import {
  BETDATA_BY_ID_GET_INVALIDATE,
  BETDATA_BY_ID_GET_REQUEST,
  BETDATA_BY_ID_GET_RESPONSE,
} from '../../actionTypes';
import {
  BetDataByIdGetInvalidateAction,
  BetDataByIdGetRequestAction,
  BetDataByIdGetResponseAction,
} from '../types';
import {BetData, BetDataAll, MatchInfo} from '../../../utils/types';
import standardizeError from '../../../utils/standardizeError';
import {beBetDataToBetDataScores, beMatchInfoToMatchInfo} from '../../../API/utils';

/**
 * Action creator for the get request of the single match info request (by id)
 * @param id
 */
const getBetDataByIdGetRequest = (id: string): BetDataByIdGetRequestAction => ({
  type: BETDATA_BY_ID_GET_REQUEST,
  payload: id,
});

/**
 * Action creator for the get response of the single match info request (by id)
 */
const getBetDataByIdGetResponse = (data: BetDataAll): BetDataByIdGetResponseAction => ({
  type: BETDATA_BY_ID_GET_RESPONSE,
  payload: {data},
});

/**
 * Action creator for the get invalidation of the single match info request (by id)
 * @param error
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getBetDataByIdGetInvalidate = (error: Error): BetDataByIdGetInvalidateAction => ({
  type: BETDATA_BY_ID_GET_INVALIDATE,
  error: standardizeError(error),
});

/**
 * // TODO: document
 */
const getBetDataById = (id: string) => (dispatch: Dispatch): Promise<any> => {
  dispatch(getBetDataByIdGetRequest(id));

  return getAPIBetDataById(id)
    .then(beBetDataToBetDataScores)
    .then((data: BetDataAll) => dispatch(getBetDataByIdGetResponse(data)))
    .catch((error: Error) => dispatch(getBetDataByIdGetInvalidate(error)));
};

export default getBetDataById;
