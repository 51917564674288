import {Team} from '../utils/types';

export enum BESport {
  SOCCER = 'soccer',
  BASKETBALL = 'basketball',
  VOLLEYBALL = 'volleyball',
  TENNIS = 'tennis',
  HANDBALL = 'handball',
  EMPTY = '',
}

export enum BEMatchState {
  NOT_STARTED = 'not_started',
  LIVE = 'live',
  MATCH_ABOUT_TO_START = 'match_about_to_start',
  CLOSED = 'closed',
  ENDED = 'ended',
}

export enum BECompetitorIdentifier {
  HOME = 'home',
  AWAY = 'away',
}

export enum BEUpdateCommandType {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ADD = 'ADD',
}

export enum BECompetitionStatus {
  PLAYOFFS = 'Playoffs',
  PLAYOUTS = 'Playouts',
}

export interface BECompetition {
  name: string;
  sportType: BESport;
}

export interface BECompetitionDetail extends BECompetition {
  _id: string;
  competitionSlug: string;
  competitionOrder: number;
  sportRadarId: string;
}

export interface BECompetitor {
  logoUrl: string;
  teamName: string;
  teamAbbreviation: string;
  teamCountry: string;
  countryCode: string;
  sportRadarCompetitorId: string;
}

export interface BEPeriodScore {
  homeScore: number;
  awayScore: number;
  periodType: string;
  periodNumber: number;
}

interface BESeason {
  name?: string;
  seasonId?: string;
  competition: BECompetition;
}

export interface BEProbability {
  name: string;
  outcome: number;
}

export interface BETeamRanking {
  associationName: string;
  rank: number;
  points: number;
  competitorId: string;
}

export interface BEMatchResult {
  teamName: string;
  teamAbbreviation: string;
  result: string;
  score: [number, number];
}

export interface BEMatchSummary {
  eventDate: string;
  matchStatus: string;
  status: BEMatchState;
  homeAbbreviation: string;
  awayAbbreviation: string;
  homeScore: number;
  awayScore: number;
}

export interface BEMatchSimple {
  _id: string;
  sportRadarEventId: string;
  sportEventSlug: string;
  sportType: BESport;
  seasonCompetitionId: string;
  competitionGroup: string;
  competitionGroupId: string;
  competitionSubGroup: string;
  status: BEMatchState;
  season: BESeason;
  startDate: string;
  matchStatus: string;
  minute: number | null;
  stoppageTime: number | null;
  homeScore: number;
  awayScore: number;
  competitors: BECompetitor[];
  periodScores: BEPeriodScore[];
  providerView: string;
  betMatch: null | {
    _id: string;
  };}

export interface BECommentary {
  reporters: Pick<BEUserType, 'fullname'>[];
  commentators: Pick<BEUserType, 'fullname'>[];
}

export interface BEMatchInfo {
  _id: string;
  sportRadarEventId: string;
  sportType: BESport;
  startDate: string;
  season: BESeason;
  commentary: BECommentary | null;
  probabilities: BEProbability[] | null;
  competitionGroup: string;
  competitionGroupId?: string;
  competitionSubGroup: string;
  stadium: string;
  competitors: BECompetitor[];
  teamRankings: BETeamRanking[] | null;
  eceArticleContent?: BEEceArticle | null;
  versus: BEMatchSummary[] | null;
  latestForms:
    | {
        qualifier: string;
        sportEventResultList: BEMatchResult[];
      }[]
    | null;
  providerView: string;
  betMatch: null | {
    _id: string;
  };
}

export interface BEEceArticle {
  eceId: string;
  title: string;
  summary: string;
  content: string;
  authorName: string;
  publishedDate: string;
  articleSection: {
    term: string;
    label: string;
  };
  articleImage: {
    url: string;
  };
}

/**
 * Represents a live match score data arriving from the backend,
 * needs to be converted to a MatchLiveData
 */
export interface BEMatchLiveData {
  _id: string;
  sportType: BESport;
  minute: number;
  stoppageTime: number;
  status: BEMatchState;
  matchStatus: string;
  homeScore: number;
  awayScore: number;
  liveBroadcast?: string;
  commentary: BECommentary; // FIXME: move to 1m polling
  periodScores: BEPeriodScore[];
}

export enum BEEventTagKeyword {
  MATCH_STARTED = 'match_started', // F, B V, T, H
  MATCH_ENDED = 'match_ended', // F, B V, T, H
  BREAK_START = 'break_start', // F, B, V, H
  PERIOD_START = 'period_start', // F, B, V, T
  PERIOD_SCORE = 'period_score', // B, H
  PERIOD_ENDED = 'period_ended', // T
  SCORE_CHANGE = 'score_change', // F, B, V, H
  VIDEO_ASSISTANT_REFEREE = 'video_assistant_referee', // F
  VIDEO_ASSISTANT_REFEREE_OVER = 'video_assistant_referee_over', // F
  SUBSTITUTION = 'substitution', // F
  YELLOW_CARD = 'yellow_card', // F
  RED_CARD = 'red_card', // F
  YELLOW_RED_CARD = 'yellow_red_card', // F
  PENALTY_SHOOTOUT = 'penalty_shootout', // F
  PENALTY_AWARDED = 'penalty_awarded', // F
  PENALTY_MISSED = 'penalty_missed', // F
  SHOT_ON_TARGET = 'shot_on_target', // F
  SHOT_OFF_TARGET = 'shot_off_target', // F
  INJURY = 'injury', // F
  INJURY_RETURN = 'injury_return', // F
  INJURY_TIME_SHOWN = 'injury_time_shown', // F
  OFFSIDE = 'offside', // F
  CORNER_KICK = 'corner_kick', // F
  THROW_IN = 'throw_in', // F
  FREE_KICK = 'free_kick', // F
  GOAL_KICK = 'goal_kick', // F
  SHOT_SAVED = 'shot_saved', // F
  STEAL = 'steal', // B
  BALL_BLOCK = 'ball_block', // B
  REBOUND = 'rebound', // B
  TURNOVER = 'turnover', // B
  FOUL = 'foul', // B
  WON_JUMP_BALL = 'won_jump_ball', // B
  FREE_THROWS_AWARDED = 'free_throws_awarded', // B
  ATTEMPT_MISSED = 'attempt_missed', // B
  VIDEO_REVIEW = 'video_review', // B
  TIMEOUT = 'timeout', // B
  TIMEOUT_OVER = 'timeout_over', // B
  POSTPONED = 'postponed', // V
  POINT = 'point', // T
  SUSPENSION = 'suspension', // H
  SEVEN_M_AWARDED = 'seven_m_awarded', // H
  SEVEN_M_MISSED = 'seven_m_missed', // H
  // editor events
  OPEN_TEXT = 'open_text', // F, B, V, T, H
  EDITOR_INFO = 'editor_info', // F, B
  EDITOR_HIGHLIGHT = 'editor_highlight', // F
  EXPERT_COMMENT = 'expert_comment', // F, B, V, T, H
  PHOTO_HIGHLIGHT = 'photo_highlight', // F, B, V, T, H
  VIDEO_HIGHLIGHT = 'video_highlight', // F, B, V, T, H
  SOCIAL = 'social', // F, B, V, T, H
  SPONSORED = 'sponsored', // F
  OPTA_DATA = 'opta_data', // F
  GOOD_OPPORTUNITY = 'good_opportunity', // F
  GOOD_MOMENT = 'good_moment', // F
  HISTORIC_FACT = 'historic_fact', // F
  VIOLENT_INCIDENT = 'violent_incident', // F
  VAR = 'var', // F
  GOALPOST = 'goalpost', // F
  BUZZER_BEATER = 'buzzer_beater', // B
  FIVE_OMADIKO_FOUL = 'five_omadiko_foul', // B
  ON_FIRE = 'on_fire', // B
  REFEREE = 'referee', // B
  TECHNICAL_FOUL = 'technical_foul', // B
  DISQUALIFICATION = 'disqualification', // B
  TECHNICAL_PENALTY = 'technical_penalty', // B
  ACE_VOLLEYBALL = 'ace_volleyball', // V
  BLOCK_VOLLEYBALL = 'block_volleyball', // V
  VAR_VOLLEYBALL = 'var_volleyball', // V
  TIMEOUT_VOLLEYBALL = 'timeout_volleyball', // V
  VIDEO_CHALLENGE = 'video_challenge', // T
  TIMEOUT_HANDBALL = 'timeout_handball', // H
  AUTOGOAL = 'autogoal', // H
}

export interface BEEventTagType {
  keyword: BEEventTagKeyword;
  displayName: string;
  sportType: string;
  source: string;
}

export interface BEPlayerType {
  sportRadarPlayerId: string;
  shirt: number;
  name: string;
  nationality: string;
  area: string;
  sportType: BESport;
}

export interface BEUserType {
  fullname: string;
  logoUrl: string;
  email: string;
}

export interface BETimeEvent {
  _id: string;
  timeLineEventId: string;
  matchTime: number;
  eventOutcome: string;
  competitorIdentifier: BECompetitorIdentifier;
  stoppageTime: number;
  stoppageTimeShown: number;
  homeScore: number;
  awayScore: number;
  server: string;
  result: string;
  points: number;
  period: string;
  periodName: string;
  pictureUrl: string;
  pictureCaption: string;
  embed: string;
  openTitle: string;
  content: string;
  sponsoredLink: string;
  sportEventId: string;
  localDateTime: Date;
  method: string;
  eventTag?: BEEventTagType;
  players?: BEPlayerType[];
  user?: BEUserType;
  relatedVideo: boolean;
  isArchived: boolean;
}

export interface BETimelineUpdateCommand {
  _id: string;
  sportEventId: string;
  commandType: BEUpdateCommandType;
  timeEvent: BETimeEvent;
}

export enum BEStatNameType {
  // FIXME: fill in missing types
  BALLPOSSESSION = 'ballPossession',
  SHOTSTOTAL = 'shotsTotal',
  SHOTSONTARGET = 'shotsOnTarget',
  CORNERKICKS = 'cornerKicks',
  FOULS = 'fouls',
  OFFSIDES = 'offsides',
  THREEPOINTATTEMPTSTOTAL = 'threePointAttemptsTotal',
  THREEPOINTATTEMPTSSUCCESSFUL = 'threePointAttemptsSuccessful',
  TWOPOINTATTEMPTSTOTAL = 'twoPointAttemptsTotal',
  TWOPOINTATTEMPTSSUCCESSFUL = 'twoPointAttemptsSuccessful',
  FREETHROWATTEMPTSTOTAL = 'freeThrowAttemptsTotal',
  FREETHROWATTEMPTSSUCCESSFUL = 'freeThrowAttemptsSuccessful',
  REBOUNDS = 'rebounds',
  TEAMTURNOVERS = 'teamTurnovers',
  STEALS = 'steals',
  ASSISTS = 'assists',
  SHOTSBLOCKED = 'shotsBlocked',
  BIGGESTLEAD = 'biggestLead',
  BREAKPOINTSWON = 'breakpointsWon',
  ACES = 'aces',
  DOUBLEFAULTS = 'doubleFaults',
  SEVENMGOALS = 'sevenmGoals',
  SHOOTINGACCURACY = 'shootingAccuracy',
  SUSPENSIONS = 'suspensions',
}

export interface BEMatchStats {
  statistics: Array<
    {
      qualifier: Team;
    } & {
      [k in BEStatNameType]?: number;
    }
  >;
}

export interface BERoster {
  qualifier: string;
  sportEventPlayerInfos: {
    isStarter: boolean;
    player: BEPlayerType;
  }[];
}

export interface BELineupData {
  competitors: Array<{
    qualifier: Team;
    teamName: string;
  }>;
  rosters: BERoster[];
}

export interface BEStanding {
  season: BESeason;
  name: string;
  teamStandings: {
    team: {
      teamName: string;
    };
    rank: number;
    played: number;
    win: number;
    loss: number;
    draw: number;
    teamPoints: number;
    currentOutcome: BECompetitionStatus;
    positionChange: number;
    pointsDiff: number;
  }[];
}

/**
 * EXTRA API TYPES
 */

export interface BEEscenicArticle {
  authItem: Array<{
    type: string;
    title: string;
    url: string;
  }>;
  sectionItem: Array<{
    term: string;
    label: string;
  }>;
  author: string;
  groupId: string;
  alternateUrl: string;
  teasertitle: string;
  mobileteaser: string;
  title: string;
  summary_teaser: string;
  summary_main: string;
  summary: string; // FIXME: mixed schema from article to pool
  supertitle_teaser: string;
  supertitle_main: string;
  published: Date;
  type: string;
  homeSectionItem: {
    term: string;
    label: string;
  };
  content: string;
  tags?: Array<{
    tag_title: string;
    tag_desc: string;
  }>;
  impressionurl?: string;
  mainImage?: Array<{
    credits: string;
    caption: string;
    type: string;
    title: string;
    url: string; // BIQ / assert URL?
  }>;
  inlineImage?: Array<{
    credits: string;
    caption: string;
    type: string;
    url: string; // TODO: assert URL?
  }>;
  id: number;
  updated: Date;
  isSponsored: string;
}

export interface BETVProgramEvent {
  id: number;
  scheduleDate: string;
  title: string;
  description: string;
  channel: {
    id: number;
    name: string;
  };
  tags: Array<{
    id: number;
    name: string;
    displayName: string;
  }>;
  programId?: string;
  links?: any[]; // BIQ: / specify type
  publications: Array<{
    id: number;
    name: string;
    secret: string;
    tags: any[]; // TODO: BI / specify type
  }>;
  externalUrl?: string;
  editedByEditor: boolean;
  isLive: boolean;
  dateView: string;
  timeView: string;
}

export interface BEMatchBetData {
  _id: string;
  sportEventSlug: string;
  competitors: BECompetitor[];
  betMatch: null | {
    _id: string;
  };
}

export interface BEBetData {
  id: number;
  matchEventId: string;
  matchEventName: string;
  matchEventUrl: string | null;
  matchSlug: string;
  matchEventDate: string;
  sportType: string;
  matchMarketList: Array<{
    id: number;
    matchMarketId: string;
    market: {
      id: number;
      marketType: string;
      marketName: string;
    };
    selectionList: Array<{
      id: number;
      selectionId: string;
      selectionName: string;
      selectionPrice: number;
    }>;
  }>;
  competition: {
    id: number;
    matchCenterCompetitionId: string;
    leagueName: string;
    sportType: string;
    betLeagueList: Array<{
      id: number;
      leagueId: string;
      sportType: string;
    }>;
    provider: {
      id: number;
      link: string;
      logo: string;
      name: string;
    };
  };
  teamList: Array<{
    id: number;
    teamsId: string;
    teamsName: string;
  }>;
}
