import {BetDataCleanup} from '../types';
import {BETDATA_CLEANUP} from '../../actionTypes';

/**
 * The following action creator tells the reducer to clean-up the state
 */
const betDataCleanup = (): BetDataCleanup => ({
  type: BETDATA_CLEANUP,
});

export default betDataCleanup;
