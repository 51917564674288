import MatchList from 'components/MatchList';
import useCompetitions from 'hooks/useCompetitions';
import React, {FC, Ref, RefObject, useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';

import {groupMatchesByCompetition} from 'utils/matchFns';

import {MatchSimple, Sport, TVProgramEvent} from 'utils/types';

import './styles.scss';

const MatchcenterScoresWidget: FC<{
  matches: MatchSimple[];
  root: HTMLElement | null;
}> = ({matches, root}: {matches: MatchSimple[]; root: HTMLElement | null}) => {
  const [competitionsState] = useCompetitions();
  const {data: competitions} = competitionsState;

  const matchGroups = useMemo(
    () => groupMatchesByCompetition(matches, competitions),
    [matches, competitions]
  );

  return (
    root &&
    createPortal(
      <section className="MatchcenterScoresWidget">
        <h1 className="MatchcenterScoresWidget__header">SCOREBOARD</h1>
        <MatchList matchGroups={matchGroups} />
      </section>,
      root
    )
  );
};

export default React.memo(MatchcenterScoresWidget);
