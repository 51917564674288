import {BEStanding} from './types';
import performQuery, {Middleware} from './performQuery';
import {createMiddleware} from './utils';

/**
 * Performs a GraphQL query that retrieves the competitionTables by seasonId and by groupname
 * @param seasonId
 * @param groupId
 */
export const standingBySeasonIdOrGroupName = (
  seasonId: string,
  groupId?: string,
  start?: number,
  end?: number
): Promise<BEStanding[]> => {
  const getRoot: Middleware = createMiddleware('standingBySeasonIdOrGroupName');
  const query = `{
    standingBySeasonIdOrGroupName(seasonId: "${seasonId}", groupId: "${
    groupId || ''
  }"${typeof start === 'number' ? `, rangeFrom: ${start}` : ''}${typeof end === 'number' ? `, rangeTo: ${end}` : ''}) {
      season {
        competition {
          name
          sportType
        }
      }
      name
      teamStandings {
        team {
          teamName
        }
        rank
        played
        win
        loss
        draw
        teamPoints
        currentOutcome
        positionChange
        pointsDiff
      }
    }
  }`;

  return performQuery(query, getRoot)();
};

export default {
  standingBySeasonIdOrGroupName,
};
