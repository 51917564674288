import {combineReducers} from 'redux';

// reducers
import tvProgramWidgetsReducer from './tvProgramWidgets/reducer';
import competitionsReducer from './competitions/competitionsReducer';
import topMatchesWidgetsReducer from './topMatchesWidgets/reducer';
import homepageWidgetsReducer from './homepageWidgets/reducer';
import scoreboardPanelsReducer from './scoreboardPanels/reducer';
import scoresWidgetsReducer from './scoresWidgets/reducer';
import standingsWidgetsReducer from './standingsWidgets/reducer';
import betDataReducer from './betData/reducer';


/**
 * Merges all the reducers into one, well defined store
 */
export default combineReducers({
  competitions: competitionsReducer,
  homepageWidgets: homepageWidgetsReducer,
  topMatchesWidgets: topMatchesWidgetsReducer,
  tvProgramWidgets: tvProgramWidgetsReducer,
  scoreboardPanels: scoreboardPanelsReducer,
  scoresWidgets: scoresWidgetsReducer,
  standingsWidgets: standingsWidgetsReducer,
  betData: betDataReducer,

});
