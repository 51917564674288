import {Dispatch} from 'redux';
import {
  STANDINGS_BY_SEASON_GROUP_GET_INVALIDATE,
  STANDINGS_BY_SEASON_GROUP_GET_REQUEST,
  STANDINGS_BY_SEASON_GROUP_GET_RESPONSE,
} from '../../actionTypes';
import {
  StandingsBySeasonGroupGetInvalidateAction,
  StandingsBySeasonGroupGetRequestAction,
  StandingsBySeasonGroupGetResponseAction,
} from '../types';
import {CompetitionTablesTeam} from '../../../utils/types';
import standardizeError from '../../../utils/standardizeError';
import {beStandingToCompetitionTableTeams} from '../../../API/utils';
import {standingBySeasonIdOrGroupName} from '../../../API/standings';

/**
 * Action creator for the get request of the competitionTables by season id and groupname
 * @param id
 */
const getStandingsBySeasonGroupGetRequest = (): StandingsBySeasonGroupGetRequestAction => ({
  type: STANDINGS_BY_SEASON_GROUP_GET_REQUEST,
});

/**
 * Action creator for the get response of the competitionTables by season id and groupname
 */
const getStandingsBySeasonGroupGetResponse = (
  widgetId: string,
  isCarousel: boolean,
  competitionName: string,
  standingGroups: {name: string; teams: CompetitionTablesTeam[]}[]
): StandingsBySeasonGroupGetResponseAction => ({
  type: STANDINGS_BY_SEASON_GROUP_GET_RESPONSE,
  payload: {widgetId, isCarousel, competitionName, standingGroups},
});

/**
 * Action creator for the get invalidation of the competitionTables by season id and groupname
 * @param error
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStandingsBySeasonGroupGetInvalidate = (
  error: Error
): StandingsBySeasonGroupGetInvalidateAction => ({
  type: STANDINGS_BY_SEASON_GROUP_GET_INVALIDATE,
  error: standardizeError(error),
});

/**
 * Thunk for getting the team competitionTables in the current season and group
 * @param seasonId
 * @param groupName
 */
const getStandingsBySeasonGroup =
  (widgetId: string, isCarousel: boolean, seasonId: string, groupName?: string, start?: number, end?: number) =>
  (dispatch: Dispatch): Promise<any> => {
    dispatch(getStandingsBySeasonGroupGetRequest());

    return standingBySeasonIdOrGroupName(seasonId, groupName, start, end)
      .then(results => ([results[0].season.competition.name, results.map(group => ({name: group.name, teams: beStandingToCompetitionTableTeams(group)}))]) as [string, {name: string; teams: CompetitionTablesTeam[]}[]])
      .then(([competitionName, standingGroups]) =>
        dispatch(getStandingsBySeasonGroupGetResponse(widgetId, isCarousel, competitionName, standingGroups))
      )
      .catch((error: Error) => dispatch(getStandingsBySeasonGroupGetInvalidate(error)));
  };

export default getStandingsBySeasonGroup;
