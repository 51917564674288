import {Dispatch} from 'redux';
import { sportEventsByIds } from 'API/sportEvents';
import {
  MATCH_LIVE_DATA_BY_IDS_GET_INVALIDATE,
  MATCH_LIVE_DATA_BY_IDS_GET_REQUEST,
  MATCH_LIVE_DATA_BY_IDS_GET_RESPONSE,
} from '../../actionTypes';
import {
  ScoresWidgetsGetInvalidateAction,
  ScoresWidgetsGetRequestAction,
  ScoresWidgetsGetResponseAction,
} from '../types';
import {CompetitionTablesTeam, MatchSimple} from '../../../utils/types';
import standardizeError from '../../../utils/standardizeError';
import {beMatchSimpleToMatchSimple, beStandingToCompetitionTableTeams} from '../../../API/utils';
import {standingBySeasonIdOrGroupName} from '../../../API/standings';

/**
 * Action creator for the get request of the competitionTables by season id and groupname
 * @param id
 */
const getScoresWidgetsGetRequest = (): ScoresWidgetsGetRequestAction => ({
  type: MATCH_LIVE_DATA_BY_IDS_GET_REQUEST,
});

/**
 * Action creator for the get response of the competitionTables by season id and groupname
 */
const getScoresWidgetsGetResponse = (widgetId: string, matches: MatchSimple[]): ScoresWidgetsGetResponseAction => ({
  type: MATCH_LIVE_DATA_BY_IDS_GET_RESPONSE,
  payload: {widgetId, matches},
});

/**
 * Action creator for the get invalidation of the competitionTables by season id and groupname
 * @param error
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getScoresWidgetsGetInvalidate = (error: Error): ScoresWidgetsGetInvalidateAction => ({
  type: MATCH_LIVE_DATA_BY_IDS_GET_INVALIDATE,
  error: standardizeError(error),
});

/**
 * Thunk for getting the team competitionTables in the current season and group
 * @param seasonId
 * @param groupName
 */
const getScoresWidgets = (widgetId: string, ids: string[]) => (dispatch: Dispatch): Promise<any> => {
  dispatch(getScoresWidgetsGetRequest());

  return sportEventsByIds(ids)
    .then((results) => results.map(beMatchSimpleToMatchSimple))
    .then((matches: MatchSimple[]) => dispatch(getScoresWidgetsGetResponse(widgetId, matches)))
    .catch((error: Error) => dispatch(getScoresWidgetsGetInvalidate(error)));
};

export default getScoresWidgets;
