import {BetData, BetDataAll} from 'utils/types';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import getBetDataByIdThunk from '../store/betData/actions/getBetDataById';
import betDataCleanup from '../store/betData/actions/cleanup';
import {AppState} from '../store/types';

/**
 * Return type of the useMatchInfo hook
 */
type UseBetDataResult = [
  {loading: boolean; data?: BetDataAll; error?: string | boolean},
  {getBetDataById: typeof getBetDataByIdThunk; cleanup: () => void}
];

/**
 * Selects the 'detail' state and sum it up into a referring object
 */
const betDataSelector = (state: AppState) => ({
  loading: state.betData.isFetching,
  data: state.betData.data,
  error: state.betData.didInvalidate ? state.betData.error : false,
});


/**
 * Wraps up the business logic of selecting the 'detail' state.
 */
const useBetData = (): UseBetDataResult => {

  const dispatch = useDispatch();
  const state = useSelector(betDataSelector);
  const getBetDataById = useCallback((id: string) => dispatch(getBetDataByIdThunk(id)), [dispatch]);
  const cleanup = useCallback(() => {
    dispatch(betDataCleanup());
  }, [dispatch]);

  return [state, {getBetDataById, cleanup}];
};

export default useBetData;